import React from 'react';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { CbAccountType } from '@npm/data-access';

import {
  IndividualInformationForm,
  ReadOnlyContactInformation,
  useIndividualInformationForm,
} from '../../../onboardingAndSubmissionSteps/ContactInformation';
import { type PostOnboardingContextType } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';

const { StepState } = Wizard;

type Props = {
  stepProps: Wizard.StepProps;
  personId: number;
};

export const IndividualInformationPostOnboarding = ({
  stepProps,
  personId,
}: Props) => {
  const { stepState, accountType } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      stepState: s.activeStepState,
      accountType: s.data.account?.type,
    })
  );
  const isIndividual = accountType.code === CbAccountType.items.PersonAccount;
  const title = isIndividual
    ? 'Contact Information'
    : 'Representative Information';

  const { form, data, isLoading, handleSubmit, isUpdating } =
    useIndividualInformationForm(personId, {
      onUpdate: () => {
        Notification.success({
          message: `You have updated your ${title}`,
        });
        stepProps.handleStepComplete();
      },
    });

  return (
    <StepContainer
      stepProps={stepProps}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      isUpdating={isUpdating}
    >
      {stepState === StepState.EDIT && (
        <IndividualInformationForm
          form={form}
          isLoading={isLoading}
          initialCountryCode={data?.contact_information?.country?.code}
          isFilled={!!data?.contact_information}
          withAutoscroll={false}
          handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
        />
      )}
      {stepState === StepState.FILLED && (
        <ReadOnlyContactInformation
          data={data}
          updateButtonText={`Update ${title}`}
        />
      )}
    </StepContainer>
  );
};
