import { useEffect, useState } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { type Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { usePreviousDistinct } from '@npm/utils';

import { usePersonaContext } from '../../Persona/PersonaContext';
import { PersonaVerificationButton } from '../../Persona/PersonaVerificationButton';

import * as S from './PersonaVerification.styles';

type Props = {
  stepProps: Wizard.StepProps;
  variant?: 'in-progress' | 'not-started';
};

export const PersonaVerification = ({
  stepProps,
  variant = 'not-started',
}: Props) => {
  const { isCompleted } = usePersonaContext();
  const previousIsCompleted = usePreviousDistinct(isCompleted);
  const [callStepComplete, setCallStepComplete] = useState(false);

  useEffect(() => {
    if (isCompleted && !previousIsCompleted) {
      setCallStepComplete(true);
    }
  }, [isCompleted, previousIsCompleted, stepProps]);

  // We need to wait two renders to call the step complete
  // because the sidebar menu is dynamic and on the first render
  // it doesn't yet contain all the steps
  useEffect(() => {
    if (callStepComplete) stepProps.handleStepComplete();
  }, [callStepComplete]);

  return (
    <S.Card>
      <Flex direction="column" justify="center" align="center" gap="lg">
        <S.PersonShieldIconWrapper>
          <Icon name="person-shield" />
        </S.PersonShieldIconWrapper>
        <Flex direction="column" align="center" gap="sm">
          <Heading textAlign="center" variant="h1" colorVariant="secondary">
            We need to get to know you better
          </Heading>
          <Text align="center" colorVariant="tertiary">
            This information is collected to populate agreements and confirm
            your identity to keep you and NPM safe.
          </Text>
        </Flex>
        <PersonaVerificationButton
          text={
            variant === 'not-started'
              ? 'Start Verification'
              : 'Continue Verification'
          }
        />
      </Flex>
    </S.Card>
  );
};
