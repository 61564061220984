import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CbBackgroundCheckState, CbOnboardingStatus } from '@npm/data-access';

import { usePostOnboardingContext } from '../../hooks';
import { personaRequiredSteps } from '../../IndividualOnboarding.config';

type PersonaContextValue = {
  isModalOpen: boolean;
  isPolling: boolean;
  isCompleted: boolean;
  openModal: () => void;
  closeModal: () => void;
  onComplete: () => void;
};

const PersonaContext = createContext<PersonaContextValue | null>(null);

const POLLING_INTERVAL = 1000;

export const PersonaContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const [pollingCounter, setIsPollingCounter] = useState(0);
  const onboardingStatusCompletedRef = useRef(false);

  const { refetchOnboardingStatus, refetchAccountStatus } =
    usePostOnboardingContext();

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    // It takes time for onboarding status to be updated by Persona webhook on backend
    // Let's try to wait 1 second - this is not a mission critical update but nice-to-have
    setTimeout(async () => {
      await refetchOnboardingStatus();
    }, POLLING_INTERVAL);
  }, [refetchOnboardingStatus]);

  const onComplete = useCallback(() => {
    setIsModalOpen(false);
    setIsPollingCounter(10);
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (pollingCounter > 0) {
      // After completing Persona flow it takes time for Persona to reach out to backend
      // via webhook. Therefore we start polling backend every second until we get the result.
      timeout = setTimeout(async () => {
        setIsPollingCounter(prev => prev - 1);

        if (!onboardingStatusCompletedRef.current) {
          const response = await refetchOnboardingStatus();

          if (
            personaRequiredSteps.every(
              step =>
                response.data[step].code === CbOnboardingStatus.items.completed
            )
          ) {
            onboardingStatusCompletedRef.current = true;
          }
        }

        if (onboardingStatusCompletedRef.current) {
          const response = await refetchAccountStatus();
          const backgroundCheckStateCode =
            response.data?.background_check_state?.code;
          if (
            backgroundCheckStateCode === CbBackgroundCheckState.items.passed ||
            backgroundCheckStateCode === CbBackgroundCheckState.items.failed
          ) {
            setIsModalOpen(false);
            setIsPollingCounter(0);
            setIsCompleted(true);
          }
        }
      }, POLLING_INTERVAL);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [pollingCounter]);

  const isPolling = pollingCounter > 0;

  const value = useMemo(
    () => ({
      isModalOpen,
      isPolling,
      openModal,
      closeModal,
      onComplete,
      isCompleted,
    }),
    [isModalOpen, openModal, closeModal, onComplete, isPolling, isCompleted]
  );

  return (
    <PersonaContext.Provider value={value}>{children}</PersonaContext.Provider>
  );
};

export const usePersonaContext = () => {
  const context = useContext(PersonaContext);
  if (!context) {
    throw new Error('usePersona must be used within a PersonaContextProvider');
  }
  return context;
};
