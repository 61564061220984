/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  MatchApi,
  IssuerEntityMatchIndexErrorCodes,
  IssuerEntityMatchIndexErrorTypes,
  TsMatchActivityIntakeCreateErrorCodes,
  MatchDesignationCreateErrorCodes,
  MatchDesignationShowErrorCodes,
  MatchDesignationShowErrorTypes,
  MatchRofrAssignCreateErrorCodes,
  MatchRofrAssignShowErrorCodes,
  MatchRofrAssignShowErrorTypes,
  MatchStatisticShowErrorCodes,
  MatchStatisticShowErrorTypes,
  MatchIndexErrorCodes,
  MatchIndexErrorTypes,
  MatchShowErrorCodes,
  MatchShowErrorTypes,
  MatchUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new MatchApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type IssuerEntityMatchIndexMethod = MatchApi['issuerEntityMatchIndex'];
export type IssuerEntityMatchIndexResponseType = MethodResult<IssuerEntityMatchIndexMethod>;

export const useIssuerEntityMatchIndex = (
  variables: Parameters<IssuerEntityMatchIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityMatchIndexMethod,
    IssuerEntityMatchIndexErrorTypes
  >
) => {
  return useQuery<
    IssuerEntityMatchIndexMethod,
    IssuerEntityMatchIndexErrorTypes
  >(
    (...args) => api.issuerEntityMatchIndex(...args),
    IssuerEntityMatchIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityMatchIndex'],
        ...apiQueryConfig.IssuerEntityMatchIndex,
        ...config?.queryConfig
      },
    },
    'IssuerEntityMatchIndex',
  );
};

export const useIssuerEntityMatchIndexInfinite = (
  variables: Parameters<IssuerEntityMatchIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerEntityMatchIndexMethod,
    IssuerEntityMatchIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerEntityMatchIndexMethod,
    IssuerEntityMatchIndexErrorTypes
  >(
    (...args) => api.issuerEntityMatchIndex(...args),
    IssuerEntityMatchIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityMatchIndex', 'IssuerEntityMatchIndexInfinite'],
        ...apiQueryConfig.IssuerEntityMatchIndex,
        ...config?.queryConfig,
      },
    },
    'IssuerEntityMatchIndex',
  );
};

export const useIssuerEntityMatchIndexLazy = (baseOptions?: {
  variables?: Parameters<IssuerEntityMatchIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityMatchIndexMethod,
    IssuerEntityMatchIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerEntityMatchIndexMethod,
    IssuerEntityMatchIndexErrorTypes
  >(
    (...args) => api.issuerEntityMatchIndex(...args),
    IssuerEntityMatchIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerEntityMatchIndex'],
        ...apiQueryConfig.IssuerEntityMatchIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerEntityMatchIndex',
  );
};


export type TsMatchActivityIntakeCreateMethod = MatchApi['tsMatchActivityIntakeCreate'];

export const useTsMatchActivityIntakeCreate = () => {
  return useMutation<TsMatchActivityIntakeCreateMethod>(
    (...args) => api.tsMatchActivityIntakeCreate(...args),
    TsMatchActivityIntakeCreateErrorCodes,
    'TsMatchActivityIntakeCreate',
    '/api/issuer-entities/{id}/ts-match-activity-intake',
    'post'
  );
};

export type MatchDesignationCreateMethod = MatchApi['matchDesignationCreate'];

export const useMatchDesignationCreate = () => {
  return useMutation<MatchDesignationCreateMethod>(
    (...args) => api.matchDesignationCreate(...args),
    MatchDesignationCreateErrorCodes,
    'MatchDesignationCreate',
    '/api/match-designation',
    'post'
  );
};

export type MatchDesignationShowMethod = MatchApi['matchDesignationShow'];
export type MatchDesignationShowResponseType = MethodResult<MatchDesignationShowMethod>;

export const useMatchDesignationShow = (
  variables: Parameters<MatchDesignationShowMethod>[0],
  config?: UseQueryConfig<
    MatchDesignationShowMethod,
    MatchDesignationShowErrorTypes
  >
) => {
  return useQuery<
    MatchDesignationShowMethod,
    MatchDesignationShowErrorTypes
  >(
    (...args) => api.matchDesignationShow(...args),
    MatchDesignationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchDesignationShow'],
        ...apiQueryConfig.MatchDesignationShow,
        ...config?.queryConfig
      },
    },
    'MatchDesignationShow',
  );
};

export const useMatchDesignationShowInfinite = (
  variables: Parameters<MatchDesignationShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    MatchDesignationShowMethod,
    MatchDesignationShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    MatchDesignationShowMethod,
    MatchDesignationShowErrorTypes
  >(
    (...args) => api.matchDesignationShow(...args),
    MatchDesignationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchDesignationShow', 'MatchDesignationShowInfinite'],
        ...apiQueryConfig.MatchDesignationShow,
        ...config?.queryConfig,
      },
    },
    'MatchDesignationShow',
  );
};

export const useMatchDesignationShowLazy = (baseOptions?: {
  variables?: Parameters<MatchDesignationShowMethod>[0],
  config?: UseQueryConfig<
    MatchDesignationShowMethod,
    MatchDesignationShowErrorTypes
  >
}) => {
  return useLazyQuery<
    MatchDesignationShowMethod,
    MatchDesignationShowErrorTypes
  >(
    (...args) => api.matchDesignationShow(...args),
    MatchDesignationShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MatchDesignationShow'],
        ...apiQueryConfig.MatchDesignationShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MatchDesignationShow',
  );
};


export type MatchRofrAssignCreateMethod = MatchApi['matchRofrAssignCreate'];

export const useMatchRofrAssignCreate = () => {
  return useMutation<MatchRofrAssignCreateMethod>(
    (...args) => api.matchRofrAssignCreate(...args),
    MatchRofrAssignCreateErrorCodes,
    'MatchRofrAssignCreate',
    '/api/match-rofr-assigns',
    'post'
  );
};

export type MatchRofrAssignShowMethod = MatchApi['matchRofrAssignShow'];
export type MatchRofrAssignShowResponseType = MethodResult<MatchRofrAssignShowMethod>;

export const useMatchRofrAssignShow = (
  variables: Parameters<MatchRofrAssignShowMethod>[0],
  config?: UseQueryConfig<
    MatchRofrAssignShowMethod,
    MatchRofrAssignShowErrorTypes
  >
) => {
  return useQuery<
    MatchRofrAssignShowMethod,
    MatchRofrAssignShowErrorTypes
  >(
    (...args) => api.matchRofrAssignShow(...args),
    MatchRofrAssignShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchRofrAssignShow'],
        ...apiQueryConfig.MatchRofrAssignShow,
        ...config?.queryConfig
      },
    },
    'MatchRofrAssignShow',
  );
};

export const useMatchRofrAssignShowInfinite = (
  variables: Parameters<MatchRofrAssignShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    MatchRofrAssignShowMethod,
    MatchRofrAssignShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    MatchRofrAssignShowMethod,
    MatchRofrAssignShowErrorTypes
  >(
    (...args) => api.matchRofrAssignShow(...args),
    MatchRofrAssignShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchRofrAssignShow', 'MatchRofrAssignShowInfinite'],
        ...apiQueryConfig.MatchRofrAssignShow,
        ...config?.queryConfig,
      },
    },
    'MatchRofrAssignShow',
  );
};

export const useMatchRofrAssignShowLazy = (baseOptions?: {
  variables?: Parameters<MatchRofrAssignShowMethod>[0],
  config?: UseQueryConfig<
    MatchRofrAssignShowMethod,
    MatchRofrAssignShowErrorTypes
  >
}) => {
  return useLazyQuery<
    MatchRofrAssignShowMethod,
    MatchRofrAssignShowErrorTypes
  >(
    (...args) => api.matchRofrAssignShow(...args),
    MatchRofrAssignShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MatchRofrAssignShow'],
        ...apiQueryConfig.MatchRofrAssignShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MatchRofrAssignShow',
  );
};


export type MatchStatisticShowMethod = MatchApi['matchStatisticShow'];
export type MatchStatisticShowResponseType = MethodResult<MatchStatisticShowMethod>;

export const useMatchStatisticShow = (
  variables: Parameters<MatchStatisticShowMethod>[0],
  config?: UseQueryConfig<
    MatchStatisticShowMethod,
    MatchStatisticShowErrorTypes
  >
) => {
  return useQuery<
    MatchStatisticShowMethod,
    MatchStatisticShowErrorTypes
  >(
    (...args) => api.matchStatisticShow(...args),
    MatchStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchStatisticShow'],
        ...apiQueryConfig.MatchStatisticShow,
        ...config?.queryConfig
      },
    },
    'MatchStatisticShow',
  );
};

export const useMatchStatisticShowInfinite = (
  variables: Parameters<MatchStatisticShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    MatchStatisticShowMethod,
    MatchStatisticShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    MatchStatisticShowMethod,
    MatchStatisticShowErrorTypes
  >(
    (...args) => api.matchStatisticShow(...args),
    MatchStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchStatisticShow', 'MatchStatisticShowInfinite'],
        ...apiQueryConfig.MatchStatisticShow,
        ...config?.queryConfig,
      },
    },
    'MatchStatisticShow',
  );
};

export const useMatchStatisticShowLazy = (baseOptions?: {
  variables?: Parameters<MatchStatisticShowMethod>[0],
  config?: UseQueryConfig<
    MatchStatisticShowMethod,
    MatchStatisticShowErrorTypes
  >
}) => {
  return useLazyQuery<
    MatchStatisticShowMethod,
    MatchStatisticShowErrorTypes
  >(
    (...args) => api.matchStatisticShow(...args),
    MatchStatisticShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MatchStatisticShow'],
        ...apiQueryConfig.MatchStatisticShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MatchStatisticShow',
  );
};


export type MatchIndexMethod = MatchApi['matchIndex'];
export type MatchIndexResponseType = MethodResult<MatchIndexMethod>;

export const useMatchIndex = (
  variables: Parameters<MatchIndexMethod>[0],
  config?: UseQueryConfig<
    MatchIndexMethod,
    MatchIndexErrorTypes
  >
) => {
  return useQuery<
    MatchIndexMethod,
    MatchIndexErrorTypes
  >(
    (...args) => api.matchIndex(...args),
    MatchIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchIndex'],
        ...apiQueryConfig.MatchIndex,
        ...config?.queryConfig
      },
    },
    'MatchIndex',
  );
};

export const useMatchIndexInfinite = (
  variables: Parameters<MatchIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    MatchIndexMethod,
    MatchIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    MatchIndexMethod,
    MatchIndexErrorTypes
  >(
    (...args) => api.matchIndex(...args),
    MatchIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchIndex', 'MatchIndexInfinite'],
        ...apiQueryConfig.MatchIndex,
        ...config?.queryConfig,
      },
    },
    'MatchIndex',
  );
};

export const useMatchIndexLazy = (baseOptions?: {
  variables?: Parameters<MatchIndexMethod>[0],
  config?: UseQueryConfig<
    MatchIndexMethod,
    MatchIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    MatchIndexMethod,
    MatchIndexErrorTypes
  >(
    (...args) => api.matchIndex(...args),
    MatchIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MatchIndex'],
        ...apiQueryConfig.MatchIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MatchIndex',
  );
};


export type MatchShowMethod = MatchApi['matchShow'];
export type MatchShowResponseType = MethodResult<MatchShowMethod>;

export const useMatchShow = (
  variables: Parameters<MatchShowMethod>[0],
  config?: UseQueryConfig<
    MatchShowMethod,
    MatchShowErrorTypes
  >
) => {
  return useQuery<
    MatchShowMethod,
    MatchShowErrorTypes
  >(
    (...args) => api.matchShow(...args),
    MatchShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchShow'],
        ...apiQueryConfig.MatchShow,
        ...config?.queryConfig
      },
    },
    'MatchShow',
  );
};

export const useMatchShowInfinite = (
  variables: Parameters<MatchShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    MatchShowMethod,
    MatchShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    MatchShowMethod,
    MatchShowErrorTypes
  >(
    (...args) => api.matchShow(...args),
    MatchShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MatchShow', 'MatchShowInfinite'],
        ...apiQueryConfig.MatchShow,
        ...config?.queryConfig,
      },
    },
    'MatchShow',
  );
};

export const useMatchShowLazy = (baseOptions?: {
  variables?: Parameters<MatchShowMethod>[0],
  config?: UseQueryConfig<
    MatchShowMethod,
    MatchShowErrorTypes
  >
}) => {
  return useLazyQuery<
    MatchShowMethod,
    MatchShowErrorTypes
  >(
    (...args) => api.matchShow(...args),
    MatchShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MatchShow'],
        ...apiQueryConfig.MatchShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MatchShow',
  );
};


export type MatchUpdateMethod = MatchApi['matchUpdate'];

export const useMatchUpdate = () => {
  return useMutation<MatchUpdateMethod>(
    (...args) => api.matchUpdate(...args),
    MatchUpdateErrorCodes,
    'MatchUpdate',
    '/api/matches/{id}',
    'put'
  );
};
