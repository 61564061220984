import { sanitize } from 'dompurify';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import { WysiwygContent } from '@npm/core/ui/components/atoms/WysiwygContent';
import { type Answer } from '@npm/data-access';

import { AccreditationDataItemHeading } from './AccreditationDataItemHeading';

import * as S from '@npm/core/ui/components/molecules/ReadOnly/ReadOnly.styles';

type Props = {
  answer: Answer;
  accreditationStatusTitle: string;
  status?: 'success' | 'error';
  signature?: Answer;
  entityRepTitle?: Answer;
  additionalAnswers?: Answer[];
  headingVariant?: React.ComponentProps<
    typeof AccreditationDataItemHeading
  >['variant'];
};

const createCompositeKey = (answerBookCode: string, fieldCode: string) => {
  return `${answerBookCode}:${fieldCode}`;
};

const codeToCustomLabelMap = new Map<string, string>([
  ['ORGANIZATION_TYPE_V2:org_type', 'Accredited Investor'],
  ['ORGANIZATION_TYPE_V2:not_available', 'Not Accredited'],
  ['ORGANIZATION_TYPE:org_type', 'Accredited Investor'],
  ['ORGANIZATION_TYPE:not_available', 'Not Accredited'],
  ['QUALIFIED_PURCHASER_TYPE:qualified_purchaser', 'Qualified Purchaser'],
  [
    'QUALIFIED_INSTITUTIONAL_BUYER_TYPE:qualified_institutional_buyer',
    'Qualified Institutional Buyer',
  ],
  ['QUALIFIED_CLIENT_TYPE:qualified_client', 'Qualified Client'],
  ['QUALIFIED_CLIENT_TYPE:not_valid', 'Not a Qualified Client'],
]);

export const AccreditationDataItem = ({
  answer,
  accreditationStatusTitle,
  status = 'success',
  signature,
  entityRepTitle,
  additionalAnswers,
  headingVariant,
}: Props) => {
  return (
    <Flex direction="column" gap="md">
      <AccreditationDataItemHeading
        title={accreditationStatusTitle}
        variant={headingVariant}
      />
      <S.Content>
        <S.DataItem>
          <Text size="sm">Your Sophistication Level</Text>
          <Flex justify="space-between" align="center">
            <Label
              variant={status}
              icon={
                <Icon name={status === 'error' ? 'info-circle' : 'check'} />
              }
            >
              {codeToCustomLabelMap.get(
                createCompositeKey(answer.answer?.book, answer.field?.code)
              )}
            </Label>
          </Flex>
        </S.DataItem>
        {answer?.answer && (
          <S.DataItem>
            <Text size="sm">You Selected</Text>
            <Text size="sm" colorVariant="primary" as="div">
              <WysiwygContent
                dangerouslySetInnerHTML={{
                  __html: sanitize(answer.answer?.name ?? EMPTY_VALUE, {
                    RETURN_TRUSTED_TYPE: true,
                  }),
                }}
              />
            </Text>
          </S.DataItem>
        )}
        {signature?.answer && (
          <S.DataItem>
            <Text size="sm">
              Signature of Authorized Person Acting on Account’s Behalf
            </Text>
            <Text size="sm" colorVariant="primary" as="div">
              {signature.answer.name}
            </Text>
          </S.DataItem>
        )}
        {entityRepTitle?.answer && (
          <S.DataItem>
            <Text size="sm">Title of Authorized Person</Text>
            <Text size="sm" colorVariant="primary" as="div">
              {entityRepTitle.answer.name}
            </Text>
          </S.DataItem>
        )}
        {additionalAnswers?.map(({ answer, field } = {}) => {
          if (!answer || !field) {
            return null;
          }

          return (
            <S.DataItem key={answer.code}>
              <Text size="sm">{field.name}</Text>
              <Text size="sm" colorVariant="primary" as="div">
                {answer.name}
              </Text>
            </S.DataItem>
          );
        })}
      </S.Content>
    </Flex>
  );
};
