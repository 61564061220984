import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';

import { usePersonaContext } from '../PersonaContext';

type Props = {
  text: string;
  buttonVariant?: 'default' | 'outline';
  isCompleted?: boolean;
};

export const PersonaVerificationButton = ({
  text,
  buttonVariant,
  isCompleted,
}: Props) => {
  const { openModal, isPolling } = usePersonaContext();

  return isCompleted ? (
    <Alert
      centered
      type="success"
      icon={<Icon name="circle-check" />}
      message="We confirmed your Identity"
    />
  ) : (
    <Button
      variant={buttonVariant || 'default'}
      onClick={openModal}
      icon={<Icon name="shield" />}
      loading={isPolling}
    >
      {text}
    </Button>
  );
};
