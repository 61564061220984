import styled from 'styled-components';

import { Loader as BaseLoader } from '@npm/core/ui/components/atoms/Loader';
import { Modal } from '@npm/core/ui/components/molecules/Modal';

export const PersonaWrapperModal = styled(Modal)`
  .ant-modal-body,
  .ant-modal-content {
    position: relative;
    padding: 0;

    iframe {
      min-height: 650px;
      width: 100%;
    }

    .ant-modal-close {
      display: none;
    }
  }

  .ant-modal-footer {
    display: none;
  }
`;

export const PersonaPlaceholder = styled.div`
  width: 400px;
  height: 650px;
`;

export const Loader = styled(BaseLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
