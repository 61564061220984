import React, { useEffect, useRef, useState } from 'react';
import Persona from 'persona-react';
import { NumberParam, useQueryParam } from 'use-query-params';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { useAccountPersonaShow } from '@npm/data-access';

import { useUserContextStore } from '../../../auth/user/context';
import { usePersonaContext } from '../PersonaContext';

import * as S from './PersonaModal.styles';

export const PersonaModal: React.FC = () => {
  const { isModalOpen, onComplete, closeModal } = usePersonaContext();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isStyleVariantSet, setIsStyleVariantSet] = useState(false);
  const [roleId] = useQueryParam('roleId', NumberParam);
  const accountId = useUserContextStore(
    store =>
      store.investorAccounts?.find(account => account.id === roleId)?.subject
        ?.id
  );

  const [isReady, setIsReady] = useState(false);
  const { isDarkMode } = useDarkModeContext();

  const styleVariant = isDarkMode ? 'dark' : 'light';

  const { data, isLoading } = useAccountPersonaShow(
    {
      accountId,
    },
    {
      queryConfig: {
        enabled: isModalOpen && accountId && !isReady,
      },
      onComplete: () => {
        if (data && (!data?.inquiry_id || !data?.session_token)) {
          closeModal();
          Notification.open({
            type: 'info',
            message: `Looks like you've already completed your Identity Verification!`,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (isModalOpen) {
      setIsStyleVariantSet(false);
    }
  }, [isModalOpen]);

  if (data && (!data?.inquiry_id || !data?.session_token)) {
    return null;
  }

  return (
    <S.PersonaWrapperModal
      width={400}
      open={isModalOpen}
      onCancel={closeModal}
      footer={null}
    >
      {(!isReady || isLoading || !isStyleVariantSet) && (
        <S.Loader size="large" />
      )}
      {!isLoading && isModalOpen && (
        <>
          <div
            ref={wrapperRef}
            style={{ display: isStyleVariantSet ? 'block' : 'none' }}
          >
            <Persona
              inquiryId={data?.inquiry_id}
              sessionToken={data?.session_token}
              environmentId="env_RYiRTVHKp9C6h9VCvxJFopD4C4bm"
              parent={wrapperRef.current}
              onReady={() => {
                setIsReady(true);
                if (!isStyleVariantSet && wrapperRef.current) {
                  const iframe = wrapperRef.current.querySelector('iframe');
                  if (iframe) {
                    const src = new URL(iframe.src);

                    if (
                      src.searchParams.get('style-variant') === styleVariant
                    ) {
                      setIsStyleVariantSet(true);
                      return;
                    }

                    src.searchParams.append('style-variant', styleVariant);
                    iframe.src = src.toString();
                    // once iframe is loaded, set isStyleVariantSet to true
                    iframe.onload = () => {
                      setIsStyleVariantSet(true);
                    };
                  }
                }
              }}
              onComplete={onComplete}
            />
          </div>

          {!isStyleVariantSet && <S.PersonaPlaceholder />}
        </>
      )}
    </S.PersonaWrapperModal>
  );
};
